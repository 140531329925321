import { connect, useSelector } from "react-redux";
import * as React from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import DropDown from "../ProductDetails/DropDown";
import { Spin } from "antd";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";

export const FavouriteTable = ({ favourites, setLoader }) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        let favouriteItems = [];

        for (let i = 0; i < favourites.length; i++) {
          console.log(favourites[i]);

          let est = await getEST(
            favourites[i].product.productJson.asin,
            favourites[i].product.productJson.overview.BSR.BSR,
            favourites[i].product.productJson.basicInfo.category
          );
          const roi = calculateROI(
            Number(favourites[i].product.costPrice),
            Number(favourites[i].product.salePrice),
            Number(favourites[i].product.productJson.profitCalculator.total_fees.total)
          );
          console.log(roi, "roidd");
          favouriteItems.push({
            key: favourites[i].product.productJson.asin,
            productId: favourites[i].productId,
            product:
              favourites[i].product.productJson.basicInfo.productName.length < 20
                ? favourites[i].product.productJson.basicInfo.productName
                : favourites[i].product.productJson.basicInfo.productName.slice(0, 20) + "...",
            status: favourites[i].status,
            source: favourites[i].product.sourceLink,
            amzLink: favourites[i].product.productJson.sellerCentral.amazonLink,
            category: favourites[i].product.productJson.basicInfo.category,
            cost: "$" + favourites[i].product.costPrice,
            sellingPrice: "$" + favourites[i].product.salePrice,
            roi: roi,
            estSales: est === -1 ? "-" : est.toLocaleString(),
            currentBsr: favourites[i].product.productJson.overview.BSR.BSR.toLocaleString(),
            "90DayBsr": favourites[i].product.productJson.ranksandprices.bsr_ranks.value_90_days.toLocaleString(),
            badges: favourites[i].product.productJson.tags.highest_rated ? "Highest Rated" : null,
            image: favourites[i].product.productJson.image,
          });
        }
        setData(favouriteItems);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [favourites]);

  const calculateROI = (cost_price, sale_price, amazonFees) => {
    const final_sale_price = sale_price - Number(amazonFees);
    console.table({ cost_price, sale_price, amazonFees, final_sale_price });
    const roi = ((final_sale_price - cost_price) / cost_price) * 100;
    return roi.toFixed(2);
  };

  const getEST = async (asin, bsr, category) => {
    try {
      // const getEST = await fetch("https://vm1.getionio.com/api/getsales", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${process.env.REACT_APP_KEEPA_TOKEN}`,
      //   },
      //   body: JSON.stringify({
      //     asin: asin,
      //     bsr: bsr,
      //     category: category,
      //   }),
      // });
      // const { sales_estimation } = await getEST.json();
      const getEST = await authenticatedInstance.post("/product/getSales", {
        asin: asin,
        bsr: bsr,
        category: category,
      });
      console.log(getEST, "getEst ");
      const { sales_estimation } = getEST.data;

      return sales_estimation.monthly_sales;
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text, record) => (
        <div className="flex  items-center">
          <img className="h-7 w-7 mr-2" src={record.image} alt="Description" />
          <Link to={`/productDetails/${record.key}`} className="text-right">
            {text ? (text.length > 50 ? text.slice(0, 50) + "..." : text) : "No Product Name"}
          </Link>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (text ? <DropDown productId={record.productId} status={record.status} /> : null),
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Selling Price",
      dataIndex: "sellingPrice",
      key: "sellingPrice",
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
    },
    {
      title: "Est. Sales",
      dataIndex: "estSales",
      key: "estSales",
    },
    {
      title: "Current BSR",
      dataIndex: "currentBsr",
      key: "currentBsr",
    },
    {
      title: "90 Day BSR",
      dataIndex: "90DayBsr",
      key: "90DayBsr",
    },
    {
      title: "Badges",
      dataIndex: "badges",
      key: "badges",
      render: (text) =>
        text
          ? // <p className="text-Inter text-sm text-yellow-600 bg-yellow-100 py-1 px-2.5 w-fit rounded- flex items-start gap-x-3md">
            {
              /* 🥇 Higest Rated */
            }
          : // </p>
            null,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (text) =>
        text ? (
          <a href={text} target="_blank" rel="noopener noreferrer" style={{ color: "#1e90ff" }}>
            View
          </a>
        ) : null,
    },
    {
      title: "AMZ Link",
      dataIndex: "amzLink",
      key: "amzLink",
      render: (text) =>
        text ? (
          <a href={text} target="_blank" rel="noopener noreferrer" style={{ color: "#1e90ff" }}>
            View
          </a>
        ) : null,
    },
  ];

  return (
    <>
      {loading ? (
        <Spin spinning={loading} size="large">
          <div className="px-8 overflow-x-hidden my-10  "></div>
        </Spin>
      ) : (
        <Table columns={columns} dataSource={data} pagination={true} scroll={{ x: 1500 }} />
      )}
    </>
  );
};
